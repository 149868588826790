import {useTranslations} from "next-intl";
import style from "./NoPhoto.module.sass";
import {classList} from "@/utils/lib.ts";

export default function NoPhoto({className}: { className?: string }) {
  const t = useTranslations()
  return <div className={classList(className, style['container'])}>
    <span className={style['text']}>
      {t("no_photo_yet")}
    </span>
  </div>
}