import {GalleryImages} from "@/utils/directus.types.ts";
import {TiqetsImageData} from "@/utils/tiqets.ts";
import GalleryImage from "@/components/images/GalleryImage.tsx";
import TiqetsImg from "@/components/images/TiqetsImg.tsx";
import NoPhoto from "@/components/placeholders/NoPhoto.tsx";

export default function FallbackImage(props: {
  galleryImage?: GalleryImages | null
  tiqetsImage?: TiqetsImageData | null
  width?: number
  height?: number
  className?: string
  fallbackAlt?: string
  fallbackTitle?: string
  loading?: "eager" | "lazy"
}) {
  return props.galleryImage
    ? <GalleryImage {...props} image={props.galleryImage}/>
    : props.tiqetsImage
      ? <TiqetsImg {...props} image={props.tiqetsImage}/>
      : <NoPhoto {...props}/>
}
