import {ReactNode} from "react";

import style from "./FlexSubSection.module.sass"
import {classList} from "@/utils/lib.ts";

export function FlexSubSection({children, className}: {children: ReactNode, className?: string}) {
  return <section className={classList(style['subsection'], className)}>
    {children}
  </section>
}
