import {BlogPosts, Cities, DirectusFiles, Products, Regions} from "@/utils/directus.types";
import {TiqetsProductData} from "@/utils/tiqets.ts";

const DIRECTUS_IMAGES_HOST = process.env.NEXT_PUBLIC_DIRECTUS_IMAGES_HOST || 'http://0.0.0.0:8055';

export const directusImageAuto = (imageObject: DirectusFiles, width?: number, height?: number) => {
  return `${DIRECTUS_IMAGES_HOST}/assets/${imageObject.id}?format=auto${width ? `&width=${width}` : ''}${height ? `&height=${height}` : ''}`
}

// fields loader shortcuts
export const TRANSLATIONS = {translations: ['*']} as const;
export const GALLERY_IMAGE = ['*', {image: ['*']}, TRANSLATIONS] as const;
export const PREVIEW = {preview: GALLERY_IMAGE} as const;
export const PREVIEW_IMAGE = {preview_image: GALLERY_IMAGE} as const;
export const REGION = {region: ['*', TRANSLATIONS]} as const;
export const COUNTRY = {country: ['*', TRANSLATIONS, PREVIEW_IMAGE, REGION]} as const;
export const CITY = {city: ['*', TRANSLATIONS, PREVIEW_IMAGE, COUNTRY]} as const;
export const BLOG_CATEGORY = {category: ['*', TRANSLATIONS]} as const;
export const TIQETS_PRODUCT = {tiqets_product: ['*', TRANSLATIONS, {tags: ['*', {tiqets_tags_id: ['*']}]}]} as const;

export function takeRegions(havingRegions: { region: Regions | null }[]): Regions[] {
  return havingRegions.map(c => c.region)
    .filter((item): item is Regions => !!item && typeof item === 'object')
    .reduce((list: Regions[], r) => {
      if (!list.find(element => element.id === r.id)) {
        list.push(r)
      }
      return list;
    }, []);
}

export function takeCities(havingCities: { city: Cities | null }[]): Cities[] {
  return havingCities.map(c => c.city)
    .filter((item): item is Cities => !!item && typeof item === 'object')
    .reduce((list: Cities[], item) => {
      if (!list.find(element => element.id === item.id)) {
        list.push(item)
      }
      return list;
    }, []);
}

export function featuredAndOtherPosts(havingPosts: {
  featured_blog_post: BlogPosts | null;
  blog_posts: BlogPosts[];
}): { featuredPost: BlogPosts | null, otherPosts: BlogPosts[] } {
  let featuredPost = havingPosts.featured_blog_post || havingPosts.blog_posts[0] || null;
  let otherPosts = havingPosts.blog_posts.filter(b => b.id !== featuredPost?.id);
  return {featuredPost, otherPosts};
}

export function takeShortText(someText: string) {
  return someText.split('\n').filter(s => !!s)[0]
}

export function ifSellingData(product: Products): TiqetsProductData | false {
  if (product.tiqets_product.data.sale_status !== 'available'
    || !product.tiqets_product.data.product_checkout_url) {
    return false
  }
  // todo: later check if tiqets product is syncing or not
  return product.tiqets_product.data
}
