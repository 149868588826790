import {getLocale} from "next-intl/server";
import {useLocale} from "next-intl";

interface BasicTranslation {
  languages_code: string,
}

interface BasicTranslatable<T extends BasicTranslation> {
  translations: T[]
}

export function entityTranslator(locale: string) {
  const f = function <X extends BasicTranslation, P extends keyof X>(entity: BasicTranslatable<X>, property: P): NonNullable<X[P]> | "" {
    return entity.translations.find(l => l.languages_code === locale)?.[property]
      || entity.translations.find(l => l.languages_code === 'en')?.[property]
      || ''
  }
  f.exact = function <X extends BasicTranslation, P extends keyof X>(entity: BasicTranslatable<X>, property: P): X[P] | undefined {
    return entity.translations.find(l => l.languages_code === locale)?.[property]
      || entity.translations.find(l => l.languages_code === 'en')?.[property]
  }
  return f
}

export async function getEntityTranslator() {
  const locale = await getLocale()
  return entityTranslator(locale)
}

export function useEntityTranslator() {
  const locale = useLocale()
  return entityTranslator(locale)
}
