import {languageCodes} from "../../translations/translations";
import {OpeningTimeDay, OpeningTimes} from "@/utils/directus.types.ts";
import {Dict} from "@/utils/lib.ts";
import {AvailabilityVariant, TimeslotVariant} from "@/integrations/tiqets.types.ts";

export interface TiqetsImageData {
  credits?: string
  alt_text?: string
  large?: string
  medium?: string
  small?: string
}

export interface TiqetsCancellation {
  policy: 'never' | 'before_timeslot' | 'before_date' | 'before_opening',
  window: string,
}

export interface TiqetsProductSupplier {
  city: string
  name: string
  address: string
  postalcode: string
  countrycode: string
}

export interface TiqetsProductData {
  id: number,
  title: string,
  tagline: string | null
  whats_included: string | null
  whats_excluded: string | null
  audio_guide_languages: languageCodes[] | null;
  live_guide_languages: languageCodes[] | null;
  instant_ticket_delivery: boolean;
  wheelchair_access: boolean;
  smartphone_ticket: boolean;
  supplier: TiqetsProductSupplier
  cancellation: TiqetsCancellation
  product_checkout_url: string
  sale_status: "available" | "unavailable"
  duration: string
  price: number
  currency: string
  skip_line: boolean
  prediscount_price?: number | null
  discount_percentage: number
  ratings: {
    total: number,
    average: number,
  }
  images: TiqetsImageData[]
  geolocation?: {
    lat: number
    lng: number
  } | null
  starting_point?: {
    lat: number
    lng: number
    label: string | null
    address: string | null
    city_id: number
  } | null
  timezone: string
  is_package: boolean
}

export function tiqetsImage(image: TiqetsImageData, size: keyof TiqetsImageData, width?: number, height?: number) {
  let link = image[size];
  if (!link) {
    return '';
  }
  const maybeImgixLink = new URL(link);
  maybeImgixLink.searchParams.delete('w');
  maybeImgixLink.searchParams.delete('h');
  maybeImgixLink.searchParams.delete('fit');
  width && maybeImgixLink.searchParams.append('w', width.toString());
  height && maybeImgixLink.searchParams.append('h', height.toString());
  (width || height) && maybeImgixLink.searchParams.append('fit', 'crop');
  return maybeImgixLink.toString();
}

export function tiqetsImageCredits(image: TiqetsImageData, fallback: string | null | undefined) {
  let by = image.credits ? ` (by ${image.credits})` : '';
  return `${image.alt_text || fallback}${by}`;
}

type DaysMap = { [key: number]: OpeningTimeDay }

export interface DerivedSchedule {
  timezone: string,
  title: string,
  week: DaysMap
}

export function tiqetsProductCheckoutUrl(
  data: TiqetsProductData,
  locale: string,
  selected_date?: string,
  selected_timeslot_id?: string,
  selected_variants?: Dict<number>,
  currency?: string,
) {
  const productAffiliateLink = new URL(data.product_checkout_url);
  productAffiliateLink.pathname = productAffiliateLink.pathname.replaceAll(/^\/\w+\//g, `/${locale}/`);
  productAffiliateLink.searchParams.delete('partner');
  productAffiliateLink.searchParams.append('partner', 'scroll_and_go');
  selected_date && productAffiliateLink.searchParams.append('selected_date', selected_date);
  selected_timeslot_id && productAffiliateLink.searchParams.append('selected_timeslot_id', selected_timeslot_id);
  selected_variants && productAffiliateLink.searchParams.append('selected_variants',
    Object.keys(selected_variants)
      .filter(id => selected_variants[id])
      .map(id => `${id}=${selected_variants[id]}`)
      .join("&")
  );
  currency && productAffiliateLink.searchParams.append('currency', currency);
  // todo: `selected_variant_language` seems partially supported with 3-symbol codes ("eng" etc.)
  //  productAffiliateLink.searchParams.append('selected_variant_language', 'eng');
  return productAffiliateLink.toString();
}

export function openingDaysToSchedules(openingTimes: OpeningTimes[]): DerivedSchedule[] {
  openingTimes = openingTimes.filter(d => !!d.openings.length);
  if (!openingTimes.length) {
    return []
  }
  return openingTimes.map(ot => ({
    timezone: ot.openings[0].timezone,
    title: ot.entity_name,
    week: ot.openings.reduce((list: DaysMap, d) => {
      const date = new Date(d.date)
      list[date.getDay()] = d
      return list
    }, {})
  }))
}

export function ecommerceItemListInfo(product: TiqetsProductData) {
  return {
    item_list_id: "TIQETS_PRODUCT_" + product.id + "_VARIANTS",
    item_list_name: product.title + " variants",
  }
}

export function ecommerceItemFromTiqets(product: TiqetsProductData, variant: AvailabilityVariant, slot: TimeslotVariant) {
  return {
    item_id: "TIQETS_PRODUCT_" + product.id,
    item_name: product.title,
    item_variant: variant.label,
    item_brand: product.supplier.name,
    ...ecommerceItemListInfo(product),
    price: slot.price_mediation.total_retail_price_incl_vat,
    quantity: slot.max_tickets,
    discount: (slot.price_mediation.prediscount_price_incl_vat
        || slot.price_mediation.total_retail_price_incl_vat)
      - slot.price_mediation.total_retail_price_incl_vat,
    affiliation: 'Tiqets',
  }
}
