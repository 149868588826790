import {tiqetsImage, tiqetsImageCredits, TiqetsImageData} from "@/utils/tiqets.ts";
import React from "react";

export default function TiqetsImg({image, width, height, className, fallbackAlt, fallbackTitle, loading}: {
  image: TiqetsImageData
  width?: number
  height?: number
  className?: string
  fallbackAlt?: string
  fallbackTitle?: string
  loading?: "eager" | "lazy"
}) {
  return <img
    loading={loading}
    className={className}
    width={width} height={height}
    alt={tiqetsImageCredits(image, fallbackAlt || fallbackTitle)}
    title={tiqetsImageCredits(image, fallbackTitle || fallbackAlt)}
    src={tiqetsImage(image, 'large', width, height)}
  />
}
