import React from "react";
import style from "./SectionFilterButtons.module.sass";
import {FlexSubSection} from "@/components/contentSections/FlexSubSection.tsx";

export interface ButtonData {
  identity: string,
  label: string,
}

export function SectionFilterButtons({options, selected, onSelect}: {
  options: ButtonData[],
  selected: ButtonData,
  onSelect: (selected: ButtonData) => void
}) {
  if (options.length <= 1) {
    return null;
  }
  return <FlexSubSection>
    <div className={style['buttons']}>
      {options.map(option => <button
        key={option.identity} onClick={() => onSelect(option)}
        className={option.identity == selected.identity ? style['selected'] : ''}>
        {option.label}
      </button>)}
    </div>
  </FlexSubSection>
}
