import {directusImageAuto} from "@/utils/directus.ts";
import {useEntityTranslator} from "@/utils/i18n-helpers.ts";
import {GalleryImages} from "@/utils/directus.types.ts";

export default function GalleryImage({image, width, height, className, loading}: {
  image: GalleryImages
  width?: number
  height?: number
  className?: string
  loading?: "eager" | "lazy"
}) {
  const et = useEntityTranslator()
  return <img
    loading={loading}
    className={className}
    width={width} height={height}
    alt={et(image, 'alt')} title={et(image, 'title')}
    src={directusImageAuto(image.image, width, height)}
  />
}
