import {Attractions, BlogPosts, Cities, Countries, Products} from "@/utils/directus.types";
import {getEntityTranslator} from "@/utils/i18n-helpers.ts";
import {getTranslations} from "next-intl/server";

export function idAndSlug(pathParam: string): [number, string] {
  const pathParts = pathParam.split('-')
  const idPart = pathParts.pop() || '0';
  const slugPart = pathParts.join('-');
  return [+idPart, slugPart]
}

export function cityLink(city: Cities) {
  return `/cities/${city.slug}-${city.id}/`
}

export function countryLink(country: Countries) {
  return `/countries/${country.slug}-${country.id}/`
}

export function blogPostLink(blogPost: BlogPosts) {
  return `/blog/${blogPost.slug}/`
}

export function attractionLink(attraction: Attractions) {
  return `/attractions/${attraction.slug}-${attraction.id}/`
}

export function productLink(product: Products) {
  return `/products/${product.slug}-${product.id}/`
}

export function productCheckoutLink(product: Products) {
  return `/products/${product.slug}-${product.id}/checkout/`
}

export async function crumbsToProduct(product: Products) {
  const et = await getEntityTranslator();
  return [
    {to: countryLink(product.attraction.city.country), label: et(product.attraction.city.country, 'title')},
    {to: cityLink(product.attraction.city), label: et(product.attraction.city, 'title')},
    {to: attractionLink(product.attraction), label: et(product.attraction, 'title')},
    {to: productLink(product), label: et(product, 'title')},
  ]
}

export async function crumbsToAttraction(attraction: Attractions) {
  const et = await getEntityTranslator();
  return [
    {to: countryLink(attraction.city.country), label: et(attraction.city.country, 'title')},
    {to: cityLink(attraction.city), label: et(attraction.city, 'title')},
    {to: attractionLink(attraction), label: et(attraction, 'title')},
  ]
}

export async function crumbsToCity(city: Cities) {
  const et = await getEntityTranslator();
  return [
    {to: countryLink(city.country), label: et(city.country, 'title')},
    {to: cityLink(city), label: et(city, 'title')},
  ]
}

export async function crumbsToCountry(country: Countries) {
  const et = await getEntityTranslator();
  return [
    {to: countryLink(country), label: et(country, 'title')},
  ]
}

export async function crumbsToBlogIndex() {
  const t = await getTranslations();
  return [
    {to: "/blog/", label: t('footer.blog')}
  ]
}


