'use client';

import React, {useState} from "react";
import {Countries} from "@/utils/directus.types";
import {takeRegions} from "@/utils/directus";
import {entityTranslator, useEntityTranslator} from "@/utils/i18n-helpers";
import {ButtonData, SectionFilterButtons} from "./SectionFilterButtons";
import style from './CountriesSection.module.sass';
import {Swiper, SwiperSlide} from "swiper/react";
import {countryLink} from "@/utils/links";
import {Navigation} from "swiper/modules";
import {FlexSliderSection} from "@/components/contentSections/sliderSection/FlexSliderSection.tsx";
import {FlexHeaderSection} from "@/components/contentSections/FlexHeaderSection.tsx";
import {useLocale, useTranslations} from "next-intl";
import {FlexSection} from "@/components/contentSections/FlexSection.tsx";
import unfortunate from "@/styles/unfortunate.module.sass";
import FallbackImage from "@/components/images/FallbackImage.tsx";
import LocaleLink from "@/components/navigation/LocaleLink.tsx";

function CountryCard({country, loading}: { country: Countries, loading?: "lazy" | "eager" }) {
  const et = useEntityTranslator();
  return <LocaleLink href={countryLink(country)}>
    <div className={style['cardSpace']}>
      <div className={style['cardContent']}>
        <FallbackImage galleryImage={country.preview_image} width={372} height={277} loading={loading}/>
        <span className={style['label']}>{et(country, 'title')}</span>
      </div>
    </div>
  </LocaleLink>
}

export function CountriesSection({countries}: { countries: Countries[] }) {
  const locale = useLocale()
  const t = useTranslations()
  const et = entityTranslator(locale)
  const world = {
    identity: 'world',
    label: t('sections.filters.world')
  };
  const [selectedRegion, setSelectedRegion] = useState<ButtonData>(world);
  const selectableRegions = [world, ...takeRegions(countries).map(r => ({identity: r.slug, label: et(r, 'title')}))];
  const onSelect = (selected: ButtonData) => {
    setSelectedRegion(selected);
  }
  return <FlexSection className={unfortunate['centerHeader']}>
    <FlexHeaderSection>
      {t('sections.countries.header')}
    </FlexHeaderSection>
    <SectionFilterButtons options={selectableRegions} selected={selectedRegion} onSelect={onSelect}/>
    <FlexSliderSection namespace="country-slide">
      <Swiper className={style['cards']} slidesPerView='auto' centeredSlides={false} centeredSlidesBounds={false}
              spaceBetween='0' modules={[Navigation]}
              navigation={{prevEl: '#prev-country-slide', nextEl: '#next-country-slide'}}>
        {countries.filter(c => (selectedRegion.identity && selectedRegion.identity !== 'world') ? c.region?.slug === selectedRegion.identity : true)
          .map((c, i) => <SwiperSlide key={c.id} className={style['card']}>
            <CountryCard country={c} loading={i > 2 ? "lazy" : "eager"}/>
          </SwiperSlide>)}
      </Swiper>
    </FlexSliderSection>
  </FlexSection>
}
